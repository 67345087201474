* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  // background: $body-bg-color;
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}
// @font-face {
//   font-family: 'Material Symbols Outlined';
//   font-style: normal;
//   font-weight: 400;
//   src: url(../../fonts/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2) format('woff2');
// }
// .material-symbols-outlined {
//   font-family: 'Material Symbols Outlined';
//   font-weight: normal;
//   font-style: normal;
//   font-size: 24px;
//   line-height: 1;
//   letter-spacing: normal;
//   text-transform: none;
//   display: inline-block;
//   white-space: nowrap;
//   word-wrap: normal;
//   direction: ltr;
//   -webkit-font-feature-settings: 'liga';
//   -webkit-font-smoothing: antialiased;
// }

// .material-symbols-outlined {
//   font-variation-settings:
//     'FILL' 0,
//     'wght' 400,
//     'GRAD' 0,
//     'opsz' 48
// }

a,
button {
  outline: none;
  border: 0;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    outline: none !important;
  }
}

img {
  max-width: 100%;
}

// li,
// ul {
//   list-style: none;
//   margin: 0;
//   padding: 0;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headings-color;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: $font-size-h1;
  // font-weight: 300;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h2 {
  font-size: $font-size-h2;
  // font-weight: 400;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h3 {
  font-size: $font-size-h3;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h4 {
  font-size: $font-size-h4;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h5 {
  font-size: $font-size-h5;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h6 {
  font-size: $font-size-h6;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

hr {
  background: $lines-color;
  border: medium none;
  display: block;
  // float: left;
  height: 1px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.noPadd {
  padding: 0 !important;
}

.noMarg {
  margin: 0 !important;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0;
  outline-offset: -2px;
}

input:active,
input:focus {
  outline: 0;
}

.modal-backdrop.in {
  background-color: #ffffff;
  opacity: 0.8;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100%;
}

bs-dropdown-container,
.bs-dropdown-container {
  z-index: 100;
}

.modal-terms {
  z-index: 99999;
}
.table--wrap {
  overflow: auto;
  table {
    tr {
      th,
      td {
        min-width: 150px;
        padding: 10px;
        border-radius: 0 !important;
        &:nth-child(13) {
          min-width: 180px;
        }
        &:nth-child(7),
        &:nth-child(17) {
          min-width: 150px;
        }
        &:last-child {
          text-align: center;
        }
        &:first-child {
          min-width: 100%;
        }
      }
    }
  }
}
.card--wrap {
  .ant-card {
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  }
  .ant-card-head-title {
    font-size: 18px;
    font-weight: bold;
  }
  .ant-card-head {
    border-bottom: 1px solid #eee;
  }
  .card--text {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 8px;
    }
    h5 {
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.filter--wrap {
  display: flex;
  align-items: center;
  cursor: default;
  width: auto;
  flex-wrap: wrap;
  align-content: flex-start;

  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
  .ico-filter {
    font-size: 22px;
    margin-right: 10px;
    margin-left: 0px;
  }
}

.filter-cus {
  margin-right: 6px;
  .fa-filter {
    background-color: rgba(0, 0, 0, 0);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: inline-block;
    flex: 0 0 24px;
    height: 24px;
    line-height: initial;
    overflow: hidden;
    white-space: nowrap;
    width: 24px;
    color: #333333;
    font-size: 24px;
  }
}
.email-icon
{
  font-size: 38px;
}