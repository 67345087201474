/* Buttons Style 
----------------------------------- */

/* Buttons Style 
----------------------------------- */
.btn-default {
  @include box-shadow(none);
  @include font-size(14px);
  @include transition(all 0.25s ease);
  align-items: center;
  background-color: transparent;
  border-radius: 3px;
  border: 2px solid $primary-color;
  color: $primary-color;
  cursor: pointer;
  display: inline-flex;
  font-family: $primary-font;
  font-weight: $font-weight-semi-bold;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 1.3;
  min-height: 40px;
  padding: 5px 20px;
  position: relative;
  text-transform: uppercase;
 
  @media (min-width: 500px) and (max-width: 568px) {
    margin-top: 0.5rem !important;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    background-color: #ffffff;
    content: '';
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    @include transform(scale(0));
    @include transition(all 0.35s ease);
    width: 100%;
    z-index: 1;
  }

  i {
    font-size: 22px;
    margin: 0 0 0 10px;
    line-height: 22px;
    position: relative;
  }

  .ico {
    margin-left: 10px;
    margin-right: -10px;
  }

  // &.white_btn {
  //   border-color: $gray-dark;
  //   color: $secondary-color;
  // }

  &.btn-open {
    border-color: rgba($color: #000000, $alpha: 0);
    color: $secondary-color;

    &:active,
    &:focus,
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0);
      border-color: $primary-color;
      color: red;
      outline: none;
    }
  }

  &.btn-primary {
    background-color: $primary-color;
    border: none;
    color: white;
  
    .ico {
      background-position: 0 bottom;
    }
    &:focus {
      background-color: $primary-color;
    }
    &:hover {
      color: #fff !important;
    }
    // @media (min-width: 500px) and (max-width: 766px) {
    //   margin-top: 1.75rem !important;
    // }
  }
  // &.filter--bt {
  //   @media (min-width: 500px) and (max-width: 766px) {
  //     margin-top: 1.75rem !important;
  //   }
  // }

  &:hover,
  &:active {
    @include box-shadow(none);
    color: #ffffff;
    outline: none;
    background-color: #fe0000;
    color: #ffffff;
    border-color: #fe0000;
    &:after {
      opacity: 0.35;
      @include transform(scale(1));
    }
    .ico {
      background-position: 0 bottom;
    }
  }
  &:focus {
    background-color: rgba($color: #000000, $alpha: 0);
    outline: 0;
    @include box-shadow(0 0 0 0.2rem rgba($primary-color, 0.15));
    &:hover,
    &:active {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 0, 0, 0.15);
}

// ========================================================================
//                    Responsive Style Starts from here
// ========================================================================

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 540px) {
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 380px) {
}
