// Botstrap-4 Custom-control Styleover-ride
.custom-control {
  display: flex;
  margin: 0;
  padding-left: 28px;
  position: relative;
  margin-right: 30px;

  &.custom-radiobox {
    
    .custom-control-input-radio,
    .custom-control-label-radio::after {
      left: 0;
      height: 18px;
      position: absolute;
      top: 0;
      width: 18px;
    }

    .custom-control-label-radio { 
      @include font-size(14px);
      color: #7E7E7E;
      font-weight: normal;
      letter-spacing: .20px;
      line-height: 18px;
      margin: 0 !important;
      max-width: 100%;
      position: static;
      z-index: 1;
      
      &:before {
        background-color: #ffffff;
        // border: #adb5bd solid 1px;
        border-radius: 100%;
        content: "";
        display: block;
        pointer-events: none;
        position: absolute;
        height: 10px;
        width: 10px;
        top: 4px;
        left: 4px;
      }

      &::after {
        background: #ffffff;
        // border: 1.5px solid #9AA7BC;
        border: 2px solid #CCCCCC;
        border-radius: 100%;
        content: "";
        display: block;
        position: absolute;
      }
    }

    .custom-control-input-radio {
      cursor: pointer;
      opacity: 0;
      z-index: 2;

      // box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(51,51,51,0.1);
      &:checked {
        &~.custom-control-label-radio {
          color: $secondary-color;

          &::before {
            background-color: $primary-color;
            // background-color: #ffffff;
            // border-color: $btn-default-border;
          }

          &::after {
            // background-image: none;
            // opacity: 1;
            background-color: rgba($color: #000000, $alpha: 0);
            border-color: $primary-color;
          }
        }
      }

      &:not(:disabled):active {
        &~.custom-control-label-radio {
          &::before {
            color: #fff;
            background-color: lighten($primary-color, 40%);
            border-color: lighten($btn-default-border, 10%);
          }
        }
      }

      // &:focus:not(:checked) {
      //   border-color: $btn-default-border;

      //   &~.custom-control-label-radio::before {
      //     border-color: lighten($btn-default-border, 10%);
      //   }
      // }

      // &:focus {
      //   &~.custom-control-label-radio::before {
      //     box-shadow: 0 0 0 .2rem rgba(251, 193, 23, .25);
      //   }
      // }

      &:disabled {
        cursor: not-allowed;

        &~.custom-control-label-radio {
          &::before {
            background-color: #f9f9f9;
            opacity: 0.5;
          }
        }
      }
    }
  }
}