
.datepicker  {
    &.dropdown-menu {
        table {
            tr {
                th {
                    color: $secondary-color;
                    font-weight: $font-weight-semi-bold;
                }
                td, th {
                    border-radius: 10px;
                    height: 35px;
                    width: 35px;
                }
                td {
                    &.active.active, &.active.highlighted.active, &.active.highlighted:active, &.active:active {
                        color: #fff;
                        background-color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }
        }
    }
}
