@import "./abstracts/variables";
@import "./abstracts/mixins";
@import "./abstracts/functions";

@import "./base/base";

@import "./components/buttons";
@import "./components/checkbox";
@import "./components/dropdowns";
@import "./components/select";
@import "./components/forms";

@import "./layout/layout";
@import "./layout/top-navbar";

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 25px #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

body {
  background-color: #ebf1f4;
  font-family: "Montserrat", sans-serif;
  // padding-top: 80px;
}

.w-100 {
  width: 100% !important;
}

.ant-select-multiple {
  .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }
}

// .ant-select-selector:hover,
// .ant-select-selector:focus-within {
//   border: 1px solid red !important;
// }

a:focus-visible,
.login_section .form_wrap .app_form .form-group .form-check input[type="checkbox"]:focus-visible+label {
  outline-color: red !important;
  outline-style: dotted !important;
  outline-width: 0.135rem !important;
}

select {
  display: block;
}

.app_form .sppinerInside .ant-spin {
  bottom: 1px;
  height: 32px;
  left: 50%;
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  width: 32px !important;
  z-index: 100;
}

.app_form .sppinerInside .ant-spin svg {
  fill: red;
  justify-content: center;
}
.ant-picker.ant-picker-range.ant-picker-focused {
  font-family: "Montserrat", sans-serif;
}
.app_form .sppinerInside .ant-select {
  width: 100%;
  font-size: 0.875rem;
}
.filter__value-container.filter__value-container--has-value.css-1fdsijx-ValueContainer {
  font-size: 0.875rem;
}
.ant-picker.ant-picker-range {
  font-family: "Montserrat", sans-serif;
}
.search_carriersfield {
  min-width: 200px;
}

.addLoadOriginDest {
  width: 100%;
}

.ant-select-clear {
  right: 38px;
  width: 22px;
  height: 22px;
  display: flex;
  font-size: 22px;
  margin-top: -11px;
}

nav.boot_pagination {
  z-index: 0;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  height: initial;
  @include box-shadow(none);

  .pagination {
    justify-content: flex-end;
    padding: 0;
    width: 100%;

    li {
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        margin: 0 2px;
      }

      &.page-item {
        height: 100%;
      }

      &.active {
        background-color: rgba(0, 0, 0, 0);

        a,
        .page-link {
          background-color: #fe0000;
          border-color: #fe0000;
          color: #ffffff;
          font-weight: 600;
        }
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;

        a,
        .page-link {
          cursor: not-allowed;
        }
      }

      .pointer-cursor {
        cursor: pointer;
      }

      a,
      .page-link {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        align-items: center;
        border-color: #b0bac9;
        border-radius: 3px;
        color: #333;
        display: flex;
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 1;
        padding: 0;
        position: relative;
        width: 45px;
        @media screen and (max-width: 575px){
          font-size: 12px;
        }
        @media screen and (max-width: 767px) {
          width: 35px;
          height: 35px;
        }

        .mdi-navigation-left,
        .mdi-navigation-right {
          background-image: url("../images/ico-date-left.png");
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: inline-block;
          height: 14px;
          line-height: initial;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-indent: 100px;
          width: 8px;
        }

        .mdi-navigation-right {
          background-image: url("../images/ico-date-right.png");
        }
      }
    }
  }
}

.login_section {
  //background-color: rgba(0, 0, 0, 0);
  //background-image: url("../images/loginBG@2x.jpg");
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: cover;
  // min-height: calc(100vh - 80px);
  // min-height: 100vh;

  &.loginMain_bg {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../images/loginBG_2x.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 80px);
    min-height: 100vh;

    .companyTagline {
      display: none;
      padding-left: 30px;

      p {
        color: #fff;
        font-size: 58px;
        font-weight: 800;
        line-height: 1;
        position: relative;
        margin: 0;
        text-transform: uppercase;
        font-style: italic;

        &:first-child,
        &:last-child {
          margin-left: 50px;
        }
      }
    }
  }

  .form_wrap {
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #ebebeb;
    box-shadow: 0 10px 50px rgba($color: #dbdbdb, $alpha: 0.3);
    max-width: 100%;
    position: relative;
    padding: 50px;
    width: 428px;

    .login_head {
      margin-bottom: 50px;

      h4 {
        font-size: 32px;
        font-weight: 700;
        color: #333333;
        line-height: 1.3;
        position: relative;
        margin: 0 0 10px;
      }

      p {
        font-size: 14px;
        margin: 0;
        position: relative;
        line-height: 1.5;
        font-weight: 400;
        width: 100%;
      }
    }

    .txt_btn {
      color: #272d3c;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      position: relative;
      text-transform: capitalize;
      text-decoration: none;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;

      &.forgot_pass {
        margin: 5px 0;
      }
    }

    .app_form {
      .form-group {
        input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input) {
          background-color: #f5f7ff;
          border-color: #e8ecfb;
          padding-left: 65px;
          position: relative;
          z-index: 1;

          @include transition(all 0.25 ease-in);

          &:focus {
            background-color: #ffffff;
            border-color: #333333;
          }
        }

        .inpt_ico,
        .btn-viewPass,
        .btn-hidePass {
          background-color: rgba(0, 0, 0, 0);
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% auto;
          display: inline-block;
          height: 34px;
          width: 34px;
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 100;

          &.user_ico {
            background-image: url("../images/ico-user.png");
          }

          &.pass_ico {
            background-image: url("../images/ico-lock.png");
          }
        }

        .btn-viewPass {
          background-image: url("../images/ico-pass-view.png");
          border: none;
          left: auto;
          margin-left: -1px;
          overflow: hidden;
          padding: 0;
          right: 15px;
          text-indent: 100px;
          white-space: nowrap;
          z-index: 10;

          &.btn-pass {
            background-image: none;
            text-indent: inherit;

            .visibility_off {
              display: none;
            }

            .visibility {
              opacity: 0.7;

              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .btn-hidePass {
          background-image: url("../images/ico-pass-hide@2x.png");
          border: none;
          left: auto;
          margin-left: -1px;
          overflow: hidden;
          padding: 0;
          right: 15px;
          text-indent: 100px;
          white-space: nowrap;
          z-index: 10;

          &.btn-pass {
            background-image: none;
            text-indent: inherit;

            .visibility_off {
              display: block;
              margin-top: -3px;

              opacity: 0.7;

              &:hover {
                opacity: 1;
              }
            }

            .visibility {
              display: none;
            }
          }
        }

        .form-check {
          padding-left: 34px;
          position: relative;

          .form-check-label::before,
          input[type="checkbox"] {
            cursor: pointer;
            height: 24px;
            left: 0;
            margin: 0;
            position: absolute;
            top: 0;
            width: 24px;
          }

          input[type="checkbox"] {
            opacity: 0;
            z-index: 10;

            &:checked+.form-check-label::before {
              border-color: #fe0000;
              box-shadow: 0 3px 9px rgba($color: #fe0000, $alpha: 0.15);
            }

            &:checked+.form-check-label::after {
              opacity: 1;
            }
          }

          .form-check-label {
            cursor: pointer;
            color: #5e5a5a;
            font-size: 14px;
            line-height: 24px;
            margin: 0;
            position: static;
            z-index: 5;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;

            &::after,
            &::before {
              content: "";
              display: inline-block;
            }

            &::before {
              border: 2px solid #e8ecfb;
              border-radius: 2px;
            }

            &::after {
              border-color: #fe0000;
              border-style: solid;
              border-width: 0 0 2px 2px;
              height: 8px;
              left: 5px;
              opacity: 0;
              position: absolute;
              top: 6px;
              transform: rotate(-45deg) !important;
              width: 14px;
            }
          }
        }
      }
    }

    .register_link {
      color: #9a9a9a;
      display: inline-block;
      margin-top: 30px;
      font-size: 14px;
      position: relative;
      line-height: 1.5;
      font-weight: 400;
      width: 100%;
    }
  }
}

.loginpassword {
  background-color: #f5f7ff;
  border-color: #e8ecfb;
  padding-left: 65px;
  position: relative;
  z-index: 1;
  padding-right: 47px !important;
  transition: all 0.25 ease-in;
}

.navbar-collapse .navbar-nav {
  margin-left: auto;
}

.navbar .navbar-brand {
  width: 220px;
  width: 180px;
}

.navbar .navbar-brand img {
  max-width: 100%;
}

.btn-default.btn-primary.loginbutton {
  font-size: 16px;
}

.forgotPass_page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .form_wrap {
    margin: 30px auto;
  }
}

.loader {
  background: rgba(255, 255, 255, 0.85);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;

  .loading_img {
    display: inline-block;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform(translate(-50%, -50%));
    width: 50px;
  }
}

.icon-selectbox .ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
}

.copyCodeDropdown {
  .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;

    .ico-copy {
      display: inline-flex;
      position: relative;
      // &::after {
      //   @include transform(translateY(-50%));
      //   background-color: rgba($color: #000000, $alpha: 0.2);
      //   border-radius: 3px;
      //   content: 'Copy code';
      //   display: inline-block;
      //   font-size: 12px;
      //   font-weight: 400;
      //   line-height: 1.3;
      //   opacity: 0;
      //   padding: 5px 10px;
      //   position: absolute;
      //   right: 100%;
      //   top: 50%;
      // }
    }

    // &:hover {
    //   .ico-copy {
    //     &::after {
    //       opacity: 1;
    //     }
    //   }
    // }
  }
}

.ant-select-selector {
  .ant-select-selection-item {
    .ico-copy {
      display: none !important;
    }
  }
}

table {
  tr {
    td {
      .ant-select {
        .ant-select-selector {
          height: 40px;
          min-height: 40px;
        }

        &.ant-select-single .ant-select-selector .ant-select-selection-item,
        &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
          line-height: 40px;
        }

        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border-color: #e8e8e8 !important;
          padding: 0 15px;
        }

        &.ant-select.ant-select-show-arrow .ant-select-arrow {
          width: 40px;
        }
      }
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-content {
    .ant-picker-cell-disabled::before {
      background: rgba(0, 0, 0, 0);
    }

    th,
    td,
    .ant-picker-cell {
      padding: 3px 0;
      text-align: center;
    }

    tr {
      background: rgba(0, 0, 0, 0);
      border: none;
    }

    table {
      tr {
        border: none;

        th {
          padding: 3px 0;
          text-align: center;
        }

        td,
        th {
          padding: 3px 0;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .login_section {
    .container {
      justify-content: center !important;
      padding: 0 15px;

      .companyTagline {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {

  nav.boot_pagination,
  .pagination_group {
    .pagination {
      justify-content: center;
      flex-wrap: wrap;

      li {
        padding: 2px;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .login_section {
    .form_wrap {
      padding: 20px;
    }
  }
}

.form_wrap {
  .txt_btn:hover {
    text-decoration: underline;
    color: #fe0000;
  }
}

.jexcel_container .jexcel_content table>thead>tr:nth-child(2)>td {
  top: 60px;
}

.app_form {
  .btn--wrap {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    gap:20px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .input-field {
      // margin: 20px 10px 0px 0px;

      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }

      .btn-filter {
        box-shadow: 0 2px 9px rgba(165, 165, 165, 0.15);
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #cccccc;
        display: flex;
        height: 44px;
        justify-content: center;
        line-height: 1.3;
        padding: 5px 10px;
        position: relative;
        transition: all 0.25s ease;
        width: 44px;
        color: #000000;

        &:hover {
          border-color: #ccc;
        }
      }

      .input-group.saveFilter-group .ant-btn-primary {
        box-shadow: none;
        align-items: center;
        background-color: #ffffff;
        border-color: #cccccc;
        border: 1px solid #cccccc;
        border-top-style: solid;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-radius: 4px;
        border-style: solid;
        color: #333333;
        display: flex;
        min-height: 44px;
        justify-content: center;
        line-height: 1.3;
        padding: 5px 10px 5px 20px;
        position: relative;
        transition: all 0.25s ease;
        white-space: break-spaces;
        word-break: break-all;
        height: auto;

        &:hover {
          border-color: #cccccc;
        }
      }

      .ico.ico_arrow {
        background-color: rgba(0, 0, 0, 0);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAM9JREFUSEtjZKAxYKSx+QyjFhAM4dEgGsRBpKZrvYCRgTH+H8O/xNuXjy3A5lQ1XcsABgbm+Qz/GSbeunKkAZsanJGsrm1dwMDE2A/S9P8/QwPQgEZkA1R1rRKYGJjmQ+UbSbYApBHFEIb/C25dPpqIRXwhUDwBV2QQTKaQYGACBRf/////LwDpBcD8P4GQy2EWErQA7GItKwNGZsYDIEtgGvHFDbJviLIApEFDw0LhHwvzBkZGRn1iDQfpI9oCggkeh4JRCwiG3GgQjYAgAgB5U0QZJJHTyQAAAABJRU5ErkJggg==);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        flex: 0 0 24px;
        height: 24px;
        margin-left: 7px;
        overflow: hidden;
        width: 24px;
      }

      .btn-default {
        min-height: 40px;
      }
    }
  }
}

.edit_icon {
  margin-left: auto;
}

.header_title {
  width: 100%;
}

.column-field {
  button {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #cccccc;
    box-shadow: none;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 1.3;
    padding: 5px 20px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: #cccccc;
    }

    span {
      display: none;
    }

    &::before {
      color: #4d4f5c;
      content: "Column";
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      margin-right: 5px;
      position: relative;
      opacity: 1;
      top: auto;
    }

    span {
      background-color: rgba(0, 0, 0, 0);
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAA1CAYAAABbRSsQAAAABHNCSVQICAgIfAhkiAAAAThJREFUWEftVzFuwlAMtckByg1KJdQuZaxAaoYs0DW5AdyMG6QzLAwwoI6wROpAbtALpK5jloACT3/IVGf7es5/0ZP9/MLU8cMd309OABVulShffyUsv7m+3bcbRE7SixbZ9G1THxHeZG0nWO1/FHi4+DwlST8mT0YAcEjwudpLXZTOxvYBoWcngJK5RC5R+GBdDyLsojYrEJIym00Gt6yiiWMCNTuSasnEj+Z1ejlxNG+a3T0cEkAPDijwjQbFcolcIqgALPAu+q8SDV/jpMeUawecwy/JqRJefB+3Fn4RDvfB8+hdwy9fhl8lKQ47C78IhwQvo9jCb3HYWhuHnp0ASuYSuUThg3U9iLCLWq1ApCyOOwu/CIcEtZlFJEvic/jVP/2yIp43ze4eDgngFgko8J0Mxepcoj8RmcZFNtsQkgAAAABJRU5ErkJggg==);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      content: "";
      display: inline-block;
      flex: 0 0 24px;
      height: 24px;
      line-height: initial;
      margin-right: -5px;
      overflow: hidden;
      text-indent: 100px;
      white-space: nowrap;
      width: 24px;
    }
  }

  &.selectColumn {
    span {
      background: transparent;
      width: auto;
      height: auto;
      text-indent: inherit;
    }
  }
}

.ant-empty-description {
  .text-center {
    color: red;
  }
}

.notes_value {
  display: inline-flex;
  align-items: center;

  button {
    background: #f7f7f7 !important;
    padding: 6px !important;
    border-radius: 6px;

    &:hover {
      background: #e5e5e5 !important;
    }
  }

}

.minHeight {
  min-height: 110px !important;
}

.sidenav {
  li {
    width: 100%;
    padding-top: 3px;
  }
}

.table--wrap {
  max-height: 700px !important;
}

.fixed_header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.loadTable {
  background-color: #f5f6fa;
}

.dropDownArrow {
  background-color: white;
}

.slick-arrow.slick-prev {
  font-size: 10px;
}

.ant-carousel .slick-prev::before {
  content: "<";
  display: block;
  position: relative;
  bottom: 20px;
  right: 20px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 33px;
  height: 33px;
}

.slick-arrow.slick-next {
  font-size: 10px;
}

.ant-carousel .slick-next::before {
  content: ">";
  display: block;
  position: relative;
  right: 18px;
  bottom: 20px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 33px;
  height: 33px;
}

.ant-carousel .slick-prev {
  left: 0;
  z-index: 99;
}

.setpermissionbody {
  background-color: #f5f5f5;
  padding: 50px;
}

.setpermissioncheckbox label input[type="checkbox"] {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.setpermissioncheckbox.switch_ele.active_swtch label {
  width: 75px !important;
}

.setpermissioncheckbox.switch_ele.active_swtch input[type="checkbox"]:checked+.lever::after {
  left: 46px;
}

.setpermissioncheckbox.switch_ele label input[type="checkbox"]:checked+.lever+.stch_lbl.yeslbl {
  color: #fff;
  left: 10px;
  left: 11px;
  right: auto;
  opacity: 1;
  transform: scale(1);
}

.set--error {
  min-height: 450px;
  align-items: center;
}

.set--error--image {
  max-width: 192px;
  margin-bottom: 40px;
}

.logintrack {
  padding: 0 30px 0;

  @media screen and (max-width:767px) {
    padding: 0 15px;
  }
}

.metricesbutton {
  top: 10px;
  margin-left: 10px;
}

.react-datepicker-wrapper {
  width: 68%;
}

.metricesbutton1 {
  min-height: 42px;
}

.react-datepicker__tab-loop {
  z-index: 999;
}

.wkLabel {
  color: #333;
  line-height: 1.3;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  // margin: 0 0 10px;
  position: relative;
  text-transform: capitalize;
  width: 100%;
}

.sidenav-main {
  z-index: 1046;
}

.customtable-1 {
  tr {
    th {
      font-weight: bold;
      background-color: #ebf1f4;
      border-bottom: solid 2px #b0bac9 !important;
      padding: 14px 10px;
      word-break: break-word;


    }
  }
}

.settingpassword {
  padding: 10px !important;
}

.HEading1 {
  font-size: 82px;
  line-height: 77px;
  color: #fff;
  font-style: italic;
  font-weight: bolder;

  @media screen and (max-width: 1200px) {
    font-size: 53px;
    line-height: 53px;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }

  &.HEading1.ml-30 {
    margin-left: -50px;
  }
}

span.ant-slider-mark-text:last-child {
  transform: translateX(-90%) !important;
}

.app_form .form-group {
  // padding: 0 24px 0 15px;

  @media screen and (max-width: 767px) {
    margin-top: 0 !important;
  }

  @media screen and (max-width: 567px) {
    width: 100%;

    &.dashborad_button_fields {
      width: auto;
    }

  }
}

.search_formControl {
  padding-right: 40px !important;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  padding: 0;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  padding-right: 40px;
  // opacity: 0.4 !important;
}

.filtersec button .ico {
  margin-right: -5px;
}

.btn-default .ico {
  margin-right: -5px;
}

form.app_form.w-25 {
  @media screen and (max-width: 567px) {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.jodit-container {
  width: 100% !important;

  .jodit-workplace {

    table,
    td,
    th,
    tr {
      // border: none !important;
      text-align: left;
    }
  }
}

.datePic,
.datePic>div.react-datepicker-wrapper,
.datePic>div>div.react-datepicker__input-container .datePic>div>div.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  padding-right: 60px;
}

.btn-viewPass-setting {
  // background-image: url('../images/ico-pass-view.png');
  background-color: transparent;
  border: none;
  left: auto;
  margin-left: -1px;
  overflow: hidden;
  padding: 0;
  right: 15px;
  //text-indent: 100px;
  white-space: nowrap;
  z-index: 10;
  width: 24px;
  height: 21px;
  position: absolute;
  top: -2px;
  bottom: 0;
  margin: auto;
  background-position: -6px;
}

.btn-hidePass-setting {
  //background-image: url('../images/ico-pass-hide@3x.png');
  border: none;
  left: auto;
  margin-left: -1px;
  overflow: hidden;
  padding: 0;
  right: 15px;
  background-color: transparent;
  //text-indent: 100px;
  white-space: nowrap;
  z-index: 10;
  width: 24px;
  height: 21px;
  position: absolute;
  top: -2px;
  bottom: 0;
  margin: auto;
  background-position: -6px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.settingpassword {
  padding-right: 41px !important;
}

.btn-viewnewPass-setting {
  //background-image: url('../images/ico-pass-view.png');
  background: none;
  border: none;
  left: auto;
  margin-left: -1px;
  overflow: hidden;
  padding: 0;
  right: 15px;
  //text-indent: 100px;
  white-space: nowrap;
  z-index: 10;
  width: 24px;
  height: 21px;
  position: absolute;
  top: -2px;
  bottom: 0;
  margin: auto;
  background-position: -6px;
}

.btn-hidenewPass-setting {
  //background-image: url('../images/ico-pass-hide@3x.png');
  background: none;
  border: none;
  left: auto;
  margin-left: -1px;
  overflow: hidden;
  padding: 0;
  //text-indent: 100px;
  white-space: nowrap;
  z-index: 10;
  width: 24px;
  height: 21px;
  position: absolute;
  right: 15px;
  top: -2px;
  bottom: 0;
  opacity: 0.7;
  margin: auto;
  background-position: -6px;

  &:hover {
    opacity: 1;
  }
}

.btn-viewconfirmPass-setting {
  // background-image: url('../images/ico-pass-view.png');
  background: none;
  border: none;
  left: auto;
  margin-left: -1px;
  overflow: hidden;
  padding: 0;
  right: 15px;
  // text-indent: 100px;
  white-space: nowrap;
  z-index: 10;
  width: 24px;
  height: 21px;
  position: absolute;
  top: -2px;
  bottom: 0;
  margin: auto;
  background-position: -6px;
}

.btn-hideconfirmPass-setting {
  // background-im  age: url('../images/ico-pass-hide@3x.png');
  background: none;
  border: none;
  left: auto;
  margin-left: -1px;
  overflow: hidden;
  padding: 0;
  // text-indent: 100px;
  white-space: nowrap;
  z-index: 10;
  width: 24px;
  height: 21px;
  position: absolute;
  right: 15px;
  top: -2px;
  bottom: 0;
  margin: auto;
  background-position: -6px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.btn-hidePass-setting .visiblity_off,
.btn-hidenewPass-setting .visiblity_off,
.btn-hideconfirmPass-setting .visiblity_off {
  display: block;
}

.btn-hidePass-setting .visibility,
.btn-hidenewPass-setting .visibility,
.btn-hideconfirmPass-setting .visibility {
  display: none;
}

.btn-viewPass-setting .visiblity_off,
.btn-viewnewPass-setting .visiblity_off,
.btn-viewconfirmPass-setting .visiblity_off {
  display: none;
}

.btn-viewPass-setting .visibility,
.btn-viewnewPass-setting .visibility,
.btn-viewconfirmPass-setting .visibility {
  display: block;
}

.checkrowafterselect {
  background-color: #f394a0;
}

#checkrowafterselectid {
  background-color: #f394a0;
}

/***********/
.app_form {
  .btn--wrap {
    flex-wrap: wrap;

    .input-field {
      @media (max-width: 991px) {
        margin: 10px 5px 0px 0px;
      }

      .btn-default {
        margin-top: 0 !important;
      }

      .input-group.saveFilter-group .ant-btn-primary {
        // min-width: 140px;
        padding: 5px 10px 5px 10px;
      }
    }
  }
}

.ant-popover.saveFilterPopover {
  .ant-popover-content .filterpopScroll ul {
    padding-left: 0;
  }
}

//updated css 22feb
.metricSelect, .filter-gaps, .import-csv-filtr, .gap-20{gap:20px!important;}
.datasource-data .ant-select-multiple .ant-select-selection-item{align-items: baseline;}
.swal2-title{
    text-align: left;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 0.8em;
    border-bottom: 1px solid #f0f0f0;
}
.swal2-close {
    font-size: 32px;
    margin-right: 10px;
    padding-top: 12px;
}
.adv-crrier-modal{width:620px!important}
.swal2-actions button{
    min-height: 44px;
    min-width: 44px;
}
.swal2-actions .swal2-cancel{
  border:1px solid red;
  color: red!important;
}

//updated css 05mar
.import-csv-filtr.d-flex{
  @media (max-width: 576px){
    display: block!important;
  }
  .uploadcsv{
    @media (max-width: 576px){
      margin-bottom: 15px;
    }
  }
}
.error-msgs{
  margin-top:5px;
}

.rfpReport-section{
  @media (max-width: 820px){
      justify-content: start!important;
  }
  @media (max-width: 576px){
    display: block!important;
  }
  .btn-default{
    @media (max-width: 576px){
      margin-right:15px;
    }
  }
}
.filterapllybutton{
  @media screen and (min-width:767px) and (max-width: 1025px){
    display: flex;
    align-items: end;
  }
  .emailfilterapplybutton{
    @media (max-width:1025px){
      margin-bottom: 0;
    }
  }
}
.manageemnailfiltersection{
  .row{
    .col-lg-6{
      .fltr_cell{
        @media (max-width: 1025px){
          margin-bottom: 0px;
        }
      }
    }
  }
}

.carrier-search-form .form-btns.d-flex{
  @media (max-width: 576px){
    grid-gap:15px;
    flex-wrap:wrap;
  }
  .form-group{margin-bottom: 10px;}
}

.myEmail-filter{
  .filtersec.ms-auto{
    @media (max-width: 480px){
      margin-left: 0!important;
    }
    div button.metricesbutton1{
      @media (max-width: 480px){
        margin-left: 0!important;
      }
    }
  }
}

.cardHeader.card .card-header {
  @media (max-width: 991px) {
    align-items: end;
    justify-content: flex-start;
  }

  .fltr_cell {
    // margin-left: 5px;

    @media (max-width: 567px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.datePic {
  align-items: end;
  justify-content: center;

  @media (max-width: 567px) {
    justify-content: left;
  }

  .btn-primary {
    display: inline-block;
    margin-right: 5px;
    min-height: 42px;
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container input {
    @media (max-width: 567px) {
      max-width: 100%;
    }
  }
}

.datepicker--wrap {
  .datepicker-close--btn {
    left: auto;
    right: 40px;
  }
}

.ant-picker-range {
  .ant-picker-clear {
    right: 32px;
  }
}

// form.app_form.w-25,
// .cardHeader.card .card-header .fltr_cell:nth-child(1) {
//   @media (max-width: 991px) {
//     width: 49% !important;
//   }

//   @media (max-width: 567px) {
//     width: 100% !important;
//   }
// }

.dash_main_container .card.cardHeader {
  padding-bottom: 30px;
}

.selectoriginloader {
  height: 39px !important;
}

.stopLoader {
  position: absolute;
  z-index: 9;
  left: 48%;
  top: 6px;
}

// .modal_form.app_form .ant-select.ant-select-single .ant-select-selector {
//   height: 53px;
// }

// .modal_form.app_form .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item,
// .modal_form.app_form .ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
//   line-height: 51px;
// }

.ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #cccccc !important;
}

// .top_filtr {
//   @media (max-width: 991px) {
//     display: contents !important;
//   }
// }

.notep {
  margin: 0 0 10px !important;
}

.column-field button:hover,
.column-field button:focus {
  border-color: #cccccc !important;
}

.ant-select .ant-select-selector {
  border: 1px solid #cccccc !important;
}

.ant-select-selection-placeholder {
  color: #999;
}

.ant-select.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #cccccc !important;
}

.app_form .btn--wrap .input-field .ico.ico_arrow {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAALJJREFUSEtjZKAxYKSx+QyjFhAM4WEaROu3H1cA+T3Q0/IBwTAAKsCnHiOIQIoZmZnugwz+z8CYGOhmtgCfJet3nUpgZPg/H6z+7z9FdEfhtYCQJciGE20BSCGGRiw+IUYNyCycqQifAcQajtcCXD6BaIKEOaEgJGgBNkuQI5yYREBURkMPEmJcDnMIURag+4QYl5NsAdgSEjMgUXGAL5MRI0d0EBFjGDY1oxYQDLmhH0QAyCxlGQM7hxMAAAAASUVORK5CYII=);
}

.ant-select.ant-select-show-arrow .ant-select-arrow {
  width: 44px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 24px;
}

.numberofrecord-field {

  .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
    color: #333;
    font-size: 14px;
  }
}

.editorsettingcss {
  height: 100% !important;
  min-height: 100% !important;
}

.jodit-wysiwyg {
  word-break: break-all;
}

.jodit-workplace {
  height: 100% !important;
}

// .jodit-wysiwyg_mode {
//   height: 100% !important;
// }

.reloadcarrieradvancedmodalbox {
  .ant-select-multiple .ant-select-selection-item-remove svg {
    margin-bottom: 4px;
  }
}

// .fltr_cell {
//   .ant-picker {
//     border: none !important;
//   }

// }
.postLoadFields {
  margin-bottom: 5px;
}

.equipmenttype {
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
    margin-bottom: 0px;
  }
}

// */********
.app_form .form-group.user input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input) {
  padding-right: 10px !important;
}

.filterapllybutton {
  // align-items: flex-end;
  // display: flex;
  margin-top: 27px;
}

.filter__contain {
  flex-wrap: wrap;
}

.filter__contain .col-3 {
  @media (max-width: 1199px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.fileinputfile {
  // justify-content: flex-end;
  width:100%;

  @media (max-width: 767px) {
    justify-content: flex-start;
  }
}

.card.import_card {
  .btn-default {
    // min-height: 40px;

    @media (max-width: 767px) {
      margin-top: 0px !important;
    }

    @media (max-width: 380px) {
      margin-top: 10px !important;
    }
  }
}

.import-col-btn {
  @media (max-width: 767px) {
    max-width: 35%;
  }

  @media (max-width: 380px) {
    max-width: 100%;
    padding: 0;
  }
}

.cardContent {
  height: 100%;
}

span.count {
  display: inline-block;
  margin: 5px 0px;
}

#counthover {
  cursor: pointer;
}

#counthover:hover {
  color: #fc001c;
}

.show_btn {
  display: inline-block;
  width: 100%;
}

.show_btn .btn-primary {
  padding: 10px;
  line-height: normal;
  min-height: auto;
  text-transform: capitalize;
}

.emailerrormodal {
  word-break: break-word;
}

.insertcarrierform {
  .app_form {
    .form-group {

      input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input),
      .app_form .form-group textarea {
        padding-right: 20px !important;
      }
    }
  }
}

.datepickerclosebuttonmanageemail img {
  border-radius: 100%;
  background-color: #8080807d;
  width: 21px;
  position: absolute;
  right: 38px;
  top: 29px;
  cursor: pointer;
  z-index: 10;
  display: none;
}

.fltr_cell:hover .datepickerclosebuttonmanageemail img {
  display: inline-block;
}

.fltr_cell {
  position: relative;
}

.notes_value {
  .ant-btn {
    border: none;
    height: auto;
    box-shadow: none;
    padding-right: 0;
    background: none;
    margin: 3px;
    padding: 0;
  }

  .ant-btn::after {
    border: none;
    text-shadow: none;
    border-color: none;
    outline: none;
  }

  .ant-btn-primary {
    &:hover {
      background: none;
    }
  }
}

.ant-popover-inner-content {
  max-width: 300px;
  font-size: 12px;
  width: 100%;
}

.advancedsearchmodalbox {
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
    margin-bottom: 0px;
  }
}

.carriergroupname {

  .app_form .form-group input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input),
  .app_form .form-group textarea {
    padding-right: 20px !important;
  }
}

.equipmentdropdown {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;
  height: 23px;
}

.equipmentdrop {
  position: relative;
}

.dash_main_wrapper {
  height: calc(100vh - 98px);
}

.listLoaderimage {
  margin: auto;
  width: 50px !important;
}

.ant-popover.saveFilterPopover {
  .ant-popover-content {
    .filter_save_content {
      .filterpopScroll ul {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}

.dateTime {
  .ant-picker {
    width: 100%;
  }
}

.daterangepicker {
  .ant-picker {
    border: 1px solid #cccccc;
    width: 100%;
    padding: 2px 15px;
    border-radius: 4px;
    min-height: 42px;
  }

  .ant-picker-input {
    border-bottom: none !important;
    height: 30px;
  }

  .ant-picker-input input:not([type]),
  input[type="date"]:not(.browser-default),
  input[type="datetime-local"]:not(.browser-default),
  input[type="datetime"]:not(.browser-default),
  input[type="email"]:not(.browser-default),
  input[type="number"]:not(.browser-default),
  input[type="password"]:not(.browser-default),
  input[type="search"]:not(.browser-default),
  input[type="tel"]:not(.browser-default),
  input[type="text"]:not(.browser-default),
  input[type="time"]:not(.browser-default),
  input[type="url"]:not(.browser-default),
  textarea.materialize-textarea {
    border-bottom: none !important;
    top: 5px;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: none;
  }
}

.profilechnageeditor {
  background-color: #ffffff00;
  width: 50%;

  .profilechangetext {
    margin-right: 10px;
  }

  img {
    width: 22px;
  }
}

.editprofilesettings {
  .avatarimage .upload-box {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    overflow: hidden;
    background: #303030;
    padding: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #fff;
      height: 100%;
    }
  }

  .outer-avatar {
    width: 100%;
  }

  .avatarimage .upload-box input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
  }

  .avatarimage {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .avatarimage {
    width: 170px;
    height: 170px;
    margin: 0 auto;

    // img {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    //   border-radius: 100%;
    // }
  }

  .form-control {
    display: block;
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .saveimagebutton {
    margin-top: 95px;
  }
}

.emailfiltercell{
  width: 300px;
  @media (max-width: 1025px) {
    width: 100%;
  }
}
.manageemaildatepicker{
  .ant-picker{
    .ant-picker-input{
      input{margin-right:10px}
    }
  }
}


.emailfiltercell{
    @media (max-width: 1025px){
    margin-bottom:15px;
  }
}
.fltr_cell{
    @media (max-width: 1025px){
      margin-bottom:15px;
    }  
}
.filterapllybutton{
    @media (max-width: 1025px){
      margin-top:0;
    } 
}

// .manageemnailfiltersection {
//   .row {
//     .emailfiltercell {
//       @media (min-width: 1600px) {
//         width: 25% !important;
//       }
//     }

//     // .filterapllybutton {
//     //   width: 15%;
//     // }
//   }
// }

.saveimagebutton {
  .profileimageerror {
    margin-left: 30px;
  }
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 0;
}

// .ant-tooltip-arrow {
//   // display: none;
// }

.originpointer {
  .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
  }
}

.manageemaildatepicker {
  input {
    border: none !important;

    // &:hover {
    //   border-bottom-color: grey !important;

    // }
  }

  // .ant-picker-focused {
  //   border-color: grey !important;

  // }

  // .ant-picker:hover {
  //   border-color: grey !important;

  // }

  .ant-picker-range .ant-picker-active-bar {
    background: none;
  }
}

.jodit-wysiwyg p {
  margin-bottom: 0 !important;
}

.dash_content_top {
  padding: 0 30px 30px;

  @media screen and (max-width:767px) {
    padding: 0 15px 30px;
  }
}
.fltr_cell .ant-picker .ant-picker-input input{
  text-align: center;
}
.fltr_cell .ant-picker .ant-picker-clear{font-size: 22px;}


.daterangepicker .ant-picker .ant-picker-input input{
  text-align: center;
}
.daterangepicker .ant-picker .ant-picker-clear{font-size: 22px;}
.manageemnailfiltersection {
  label {
    color: #000000;
    margin: 0 0 5px;
  }

  .emailfilterstatus {
    .ant-select {
      display: inherit;
    }
  }


  .ant-picker.ant-picker-range.date {
    height: 44px;
    padding: 5px 15px;
    border-radius: 4px;
    border-color: #cccccc;
    font-size: 0.875rem;
  }

  .emailfiltercell {
    .fltr_cell {
      display: inline-grid;
      width: 100%;
    }
  }

  .ant-picker-range .ant-picker-clear {
    right: 32px !important;
    font-size: 22px;
  }

  .ant-picker-input {
    top: 4px;
  }
}
.swal2-title{text-align: center;}

.fliter-space{
  .top_filtr{
    gap: 20px;
  }
}

.manageunsubscribeemailfiltersection {
  label {
    color: #000000;
  }

  .emailfilterstatus {
    .ant-select {
      display: inherit;
    }
  }

  .ant-picker.ant-picker-range.date {
    height: 44px;
    padding: 5px 15px;
    border-radius: 4px;
    border-color: #cccccc;
  }

  .emailfiltercell {
    .fltr_cell {
      display: inline-grid;
      width: 100%;
    }
  }

  .ant-picker-range .ant-picker-clear {
    right: 32px !important;
  }

  .ant-picker-input {
    top: 0px;
    margin-right: 15px;
  }
}

.dateRangeBlueBorder{
  border-color: black !important;
}
// .emailfiltercell{
.card .card-header input.search_formControl,
.card .card-header input[type="text"]:not(.browser-default):focus:not([readonly]).search_formControl {
  border: 1px solid #cccccc !important;
  text-overflow: ellipsis;
}

// }

@media screen and (max-width: 767px) {
  .card.card-head .row>div {
    margin-top: 30px;
  }

  .card.card-head .row>div:first-child {
    margin: 0;
  }

  .card.card-head .row>div:nth-child(2) {
    margin-top: 20px;
  }
}

.mail-stats {
  @media (min-width: 1200px) and (max-width: 1438px) {
    .stats_col {
      flex: 0 0 auto;
      // width: 24.33333333%;

      h3 {
        font-size: 14px !important;
      }
    }

    .mail-stats .stats_col .cardContent .img_wrap {
      border-radius: 100px;
      align-items: center;
      display: flex;
      flex: 0 0 59px;
      justify-content: center;
      height: 59px;
      width: 59px;
    }
  }
}

.templetEle {
  .ant-btn-primary {
    background: none;
    border: none;
    color: black;
    text-shadow: none;
    box-shadow: none;
  }
}

.noti-name {
  word-break: break-all;
  color: black;
  font-size: 14px;
}

.card-head {
  .ant-select-selection-placeholder {
    color: #000;
  }

  .form-control {
    // height: 40px;
    padding: 8px 15px;
    min-height: 44px;
  }
}

.insertcarrierdatadropdown {

  .input-group .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .input-group .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 33px;
  }

  // svg {
  //   margin-bottom: 4px;
  // }
}

.mail-stats .stats_col .cardContent h3,
.mail-stats .stats_col .cardContent .stat_val {
  font-size: 20px;
}

button.btn-primary.btn-default.metricesbutton1.ms-2 {
  right: 6px;

  @media screen and (max-width:767px) {
    right: 0;
  }
}

.mcdotfield {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 15px;

  .ant-select-selection-item {
    white-space: unset;
  }
}

.mcdotfield .ant-select {
  width: 100%;
}

.mcdotfield .ant-select .ant-select-selector {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.mcdotfield .ant-select:first-child {
  width: auto;
}

.mcdotfield .ant-select:first-child:after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #ccc;
  right: 0;
  top: 0;
  bottom: 0;
}

.mcdotfield .ant-select .ant-select-selector .ant-select-selection-item {
  padding-right: 44px;
  padding-left: 15px;
}

// .mcdotfield .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
//padding-left: 15px;
// }

.mcdotfield .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search input {
  padding-left: 15px;
}

.mcdotfield .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search .ant-select-selection-placeholder {
  padding-left: 15px !important;
}

.mcdotfield .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0;
}

@media screen and (max-width: 767px) {
  .mcdotfield {
    flex-direction: column;
  }

  .mcdotfield .ant-select:first-child {
    width: 100%;
  }

  .mcdotfield .ant-select:first-child:after {
    bottom: 0;
    width: 100%;
    height: 1px;
    top: unset;
  }
}

.range-slide-box {
  .ant-slider-track {
    background: rgb(14, 13, 13) !important;
    //background: rgb(216, 16, 16) !important;
  }

  // .ant-slider-dot-active {
  //   border-color: rgb(59, 57, 57) !important;
  // }

  .ant-slider-handle {
    border-color: rgb(59, 57, 57) !important;
  }

  .ant-slider-dot {
    background-color: #f5f1f1;
    border-color: rgb(59, 57, 57) !important;
  }

  .ant-slider-rail {
    background-color: rgb(201, 197, 197) !important;
  }
}

// .equipmentdropinsertcarrierdata .ant-select .ant-select-selector:after {
//   background-color: rgba(0, 0, 0, 0);
//   background-image: url("../images/ico-down-arrow@2x.png");
//   background-repeat: no-repeat;
//   background-size: 26px;
//   width: 22px;
//   height: 20px;
//   right: 15px;
//   margin: auto;
//   content: "";
//   position: absolute;
//   justify-content: center;
// }

.metricsgraph {
  margin-bottom: 7px !important;
}

.userselectdropdown {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
  }

  .ant-spin {
    fill: red;
    color: red;
    position: absolute;
    z-index: 1000;
    margin-left: 60px;
    margin-top: 9px;
  }
}

// @media (min-width: 768px) {
//   .dotmcnumbersearchbutton {
//     top: 36px;
//   }
// }

.jodit-dialog__content {
  .jodit-form__group {
    .jodit-input_group-buttons>.jodit-button {
      display: none;
    }
  }
}

.manageemaillabel {
  font-size: 0.875rem;
  // font-weight: 500;
}

.metrixgraphselectuser {
  .ant-select-clear {
    right: 36px;
  }
}

.manageemailtablestyle {
  word-break: break-all;
}

.app_form .text-danger {
  font-size: 12px;
}

.error-advance {
  position: absolute;
  font-size: 12px;
  text-align: left;
  width: 100%;
  bottom: -20px;
}

.manageemailtable {
  word-break: break-all;
}

.listofalert {
  padding-left: 0;
}

.advancedsearchcarrier {
  column-gap: 25px;

  @media screen and (max-width:567px) {
    flex-wrap: wrap;
    padding: 0;
  }
}

.handlecollapsebuttoninsertcarrierdata {
  .collapsebutton {
    background: #ffffff00;

    .dropdownBtn {
      font-size: 50px;
      color: #78787887
    }
  }
}

.ArriveEarlyDatedatepicker {
  .ant-picker {
    width: 100%;
  }
}

.mcdotfield {
  .ant-spin {
    bottom: 1px;
    height: 32px;
    left: 50%;
    position: absolute;
    right: 0;
    transform: translateX(-50%);
    width: 32px !important;
    z-index: 100;
  }

  .ant-spin svg {
    fill: red;
    justify-content: center;
  }
}

.handleequipmentininsercarrier {
  // min-width: 38%;
  min-width: 100%;

  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
}

.multipal-origin-destination-field {
  button.btn-default {
    border-radius: 100%;
    width: 3px;
    height: 2px;
  }
}

.multipal-origin-destination-field {
  .form-group {
    justify-content: flex-end;
  }

  .add-icon {
    font-size: 20px;
  }

  .remove-icon {
    font-size: 15px;
  }
}

.notificationScroll {
  ul {
    box-shadow: none;
  }
}

.email_sendtofield {

  // min-width: 35%;
  .ant-select-selector {
    max-height: 200px;
    overflow-y: auto;
  }
}

.reloadcarriersearchinputfield {
  min-width: 35%;

  .ant-select-selector {
    padding-right: 55px !important;
    max-height: 100px;
    overflow-y: auto;
  }
}

.ant-picker-separator {
  display: flex;
}

.ant-picker.ant-picker-range {
  width: 100%;
}

.ant-select-arrow,
span.ico_arrow {
  transition: all 0.3s ease;
}

.ant-popover-open span.ico_arrow {
  transform: rotate(-180deg);
}

.ant-select-selector {
  box-shadow: none !important;
}

input,
.ant-picker-focused {
  box-shadow: none !important;
}

.ant-btn-primary:after {
  display: none !important;
}

.manageemailtable th:nth-child(3) {
  white-space: nowrap;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px;
}

// input.form-control,.column-field button {height: 44px;}
.column-field button {
  height: 44px;
}

.btn-default {
  min-height: 44px;
  min-width: 44px;
  gap: 10px;
}

.filterpopScrollcolumn ul li span {
  white-space: nowrap;
}

.filterpopScrollcolumn ul li input[type="checkbox"] {
  min-width: 15px !important;
  accent-color: #0075ff;
}

.ant-picker-suffix {
  cursor: pointer;
}

.mcdotfield {
  padding: 0;
}

.ant-select-arrow {
  pointer-events: inherit;
  display: none !important;
}

.ant-select-open .ant-select-selector .ant-select-selection-search:after,
.multiple_select.ant-select-open .ant-select-selector:after {
  transform: rotate(-180deg);
}

.mcdotfield span.ant-select-selection-placeholder,
.mcdotfield span.ant-select-selection-placeholder {
  padding-left: 15px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: unset;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  width: 100%;
  left: 0;
}

.ant-select-selector .ant-select-selection-search:after,
.multiple_select .ant-select-selector:after {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAALJJREFUSEtjZKAxYKSx+QyjFhAM4WEaROu3H1cA+T3Q0/IBwTAAKsCnHiOIQIoZmZnugwz+z8CYGOhmtgCfJet3nUpgZPg/H6z+7z9FdEfhtYCQJciGE20BSCGGRiw+IUYNyCycqQifAcQajtcCXD6BaIKEOaEgJGgBNkuQI5yYREBURkMPEmJcDnMIURag+4QYl5NsAdgSEjMgUXGAL5MRI0d0EBFjGDY1oxYQDLmhH0QAyCxlGQM7hxMAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  display: inline-block;
  fill: transparent;
  height: 24px;
  opacity: 1;
  right: 10px;
  width: 24px !important;
  content: "" !important;
  visibility: visible !important;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  transition: all 0.3s ease;
  cursor: pointer;
}

.reloadcarriersearchinputfield .multiple_select .ant-select-selector:after {
  display: none;
}

.reloadcarriersearchinputfield .multiple_select:after {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAALJJREFUSEtjZKAxYKSx+QyjFhAM4WEaROu3H1cA+T3Q0/IBwTAAKsCnHiOIQIoZmZnugwz+z8CYGOhmtgCfJet3nUpgZPg/H6z+7z9FdEfhtYCQJciGE20BSCGGRiw+IUYNyCycqQifAcQajtcCXD6BaIKEOaEgJGgBNkuQI5yYREBURkMPEmJcDnMIURag+4QYl5NsAdgSEjMgUXGAL5MRI0d0EBFjGDY1oxYQDLmhH0QAyCxlGQM7hxMAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  display: inline-block;
  fill: transparent;
  height: 24px;
  pointer-events: none;
  opacity: 1;
  right: 15px;
  width: 24px !important;
  content: "" !important;
  visibility: visible !important;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  transition: all 0.3s ease;
  cursor: pointer;
}

.reloadcarriersearchinputfield .multiple_select.ant-select-open:after {
  transform: rotate(-180deg);
}

.multiple_select .ant-select-selector .ant-select-selection-search:after,
.ant-select-multiple .ant-select-selection-search:after {
  display: none;
}

.ant-select.custom-arrow .ant-select-selector {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAALJJREFUSEtjZKAxYKSx+QyjFhAM4WEaROu3H1cA+T3Q0/IBwTAAKsCnHiOIQIoZmZnugwz+z8CYGOhmtgCfJet3nUpgZPg/H6z+7z9FdEfhtYCQJciGE20BSCGGRiw+IUYNyCycqQifAcQajtcCXD6BaIKEOaEgJGgBNkuQI5yYREBURkMPEmJcDnMIURag+4QYl5NsAdgSEjMgUXGAL5MRI0d0EBFjGDY1oxYQDLmhH0QAyCxlGQM7hxMAAAAASUVORK5CYII=) right 0.8rem center/24px no-repeat;
}

.ant-select-multiple .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}

.error-advance-market-search {
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.visiblity_off,
.visibility,
.visibility_off {
  font-size: 22px;
}

.comment-icon {
  transition: all 0.25s ease;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex !important;
  height: 32px;
  justify-content: center;
  width: 32px;
  font-size: 20px;
  color: #b6c0ce;


}

.ant-message {
  z-index: 99999 !important;
}

.top-header {
  background: #fff;
  padding: 20px 35px;
}

.content-section {
  min-height: calc(100vh - 138px);
  padding: 20px;
}

.handleCarrierPortalSearchCollapse {
  position: absolute;
  right: 20px;
  top: 5px;

  @media screen and (max-width:567px) {
    right: 5px;
  }

  .collapsebutton {
    background: #ffffff00;
  }
}

.carrierPortalDropdown {
  justify-content: flex-end;
}

.carrierPortal {
  .expnadicon {
    font-size: 57px;
    color: #cccccc;

    @media screen and (max-width:567px) {
      font-size: 45px;
    }
  }

  .tablecollapsebutton {
    background: none;
  }

  .tablecollapsematrialicon {
    font-size: 34px;
    color: #7f7f7f;
    background: none;
  }

  .tablebuttonbooknow {
    justify-content: center;
  }
}

.newRecord {
  // font-size: 30px;
  color: #b8001b;
}

.updated {
  font-size: 30px;
  color: #65fb03;
}

.duplicate {
  color: #e9a24c;
}

.errors {
  color: #fe0000;
  font-size: 30px;
}

.app_form .form-group.email-head {
  flex-direction: row !important;
}

.btn---top {
  margin-top: 28px;
}

.swapOriginDestinationDashboard {
  position: relative;
  height: 25px;
  top: 32px;
  @media screen and (max-width:1023px) {
    top: 0;
    margin: 0 auto 15px;
  }

  .swapOriginDestinationDashboardButton {
    background: none;
  }
}

.notification-icons {
  .notification-icons-image {
    font-size: 25px;
  }
}

.notification_message_empty {
  height: calc(100vh - 207px);

  .notification_message_templetEle {
    padding: 10px 10px 10px 20px;
    width: 100%;
  }
}

.notification-button-hover:hover {
  .notification-icons {
    color: red;
  }
}

.dateColumnMnageEmail {
  word-break: break-word;
}

.merticsSearch {
  .ant-select-selection-item {
    padding-right: 40px !important;
  }
}

.metricsClearSearch {
  .ant-select-clear {
    right: 35px !important;
  }
}

.marketdatatable {
  table tr td:last-child {
    text-align: left !important;
  }
}

.ant-select-selection-overflow {
  padding-right: 29px;
}

.ant-select.ant-select-multiple .ant-select-selection-placeholder {
  padding-right: 40px;
}

.ant-empty {
  margin: auto;
}
.ant-picker.ant-picker-range.date.ant-picker-focused {
  font-size:0.875rem;
}
.emailfilterstatus .mangeEmailFilter input {
  height: 30px;
  font-size: 0.875rem !important;
}

// .mangeEmailFilter {
//   .css-1pahdxg-control:hover {
//     border: #cccccc;
//   }
//   .css-1pahdxg-control {
//     border: #cccccc;
//     box-shadow: 0 0 0 1px #7f7f7f;
//   }
//   .css-1okebmr-indicatorSeparator {
//     display: none;
//   }
//   // .css-1pahdxg-control svg {
//   //   transform: rotate(-180deg);
//   // }
//   // .css-1s2u09g-control svg {
//   //   transform: rotate(180deg);
//   // }
//   .css-9gakcf-option:active {
//     background-color: #ff3426;
// }
// .css-9gakcf-option{
//   background-color: #ff3426;

// }
// .css-1n7v3ny-option{
//   background-color: #ffdede;
// }
// .css-1n7v3ny-option:active {
//     background-color: #ffdede;
// }
//   // .css-tlfecz-indicatorContainer{
//   // }
// }

.filter {
  &__menu {
    margin: 0.125rem auto;
  }

  &__option {
    background-color: white;

    &--is-focused {
      background-color: #ffdede !important;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid white;
  }

  &__menu-list {
    background-color: white;
  }

  &__filter__option:active {
    background-color: #ffdede;
  }

  &__option--is-selected {
    color: rgb(255, 11, 11) !important;
    background-color: white !important;
  }

  &__option>&__option--is-focused {
    background-color: #ffdede !important;
  }

  &__control {
    border: #cccccc !important;
    box-shadow: 0 0 0 1px #05050533 !important;
  }

  &__control:hover {
    border: #cccccc !important;
  }
}

.carrierPortaldate {
  .ant-picker-suffix {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Drayagecollapsebutton {
  background-color: white;
  position: relative;
  top: -37px;
  float: right;
}

.postingLoadEquip {
  .ant-select-selection-overflow {
    padding-right: 0px;
  }
}
.swal2-close:focus {box-shadow:none}

.swal2-close:hover {
  // transform: none;
  // background: 0 0;
  color: grey;
  // border: 1px solid grey;
}

.swal2-styled.swal2-cancel {
  border-radius: 0.25em;
  background: initial;
  background-color: white;
  color: black;
  font-size: 1em;
}

.swal2-actions:not(.swal2-loading) .swal2-styled.swal2-cancel:hover {
  background-image: none;
  border: 1px solid red;
  color: red;
}

.ant-dropdown {
  z-index: 9;
}

.errorPage {
  .errorPageImage {
    svg {
      font-size: 210px;
      color: red;

    }
  }

  .errorPage404 {
    font-size: 40px;
    font-weight: bolder;
  }

  .errorPageMessage {}
}


.carrierPortaldate input[type=number] {
  -moz-appearance: textfield;
}

.carrierPortaldate input::-webkit-outer-spin-button,
.carrierPortaldate input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-popover.dashboardPopoverValue {
  z-index: 9 !important;
}

.ant-select-dropdown {
  z-index: 9999;
}

.ant-popover.reloadCarrierPopover {
  z-index: 9 !important;
}

.ant-select-dropdown.dashboardRecords {
  z-index: 9 !important;

}

.ant-select-dropdown.manageStaffacessUserRole {
  z-index: 9 !important;

}

.ant-select-dropdown.metricsFilterDropdown {
  z-index: 9 !important;

}

.ant-select-dropdown.insertCarrierDataDropdown {
  z-index: 9 !important;
  width: 230px !important;

}

.ant-select-dropdown.reloadCarrierDropdown {
  z-index: 9 !important;

}

.dashboardSortingButton {
  background: none;
}

.marketSearch {
  .ant-select-selection-item .justify-content-between {
    justify-content: flex-start !important;
    gap: 10px;

  }
}

// .laneUser {
//   .css-b62m3t-container {
//     position: relative;
//     box-sizing: border-box;
//     Z-INDEX: 19;
//   }
// }
// }

.carrierDetail {
  .expnadicon {
    height: 35px;
    width: 35px;
    background-color: white;
  }
}

.dashboardTableStyle {
  width: 0.625rem !important;
}

.greenColorDashBoardNote {
  svg {
    color: green;
  }
}

.redColorDashboardNote {
  svg {
    color: red;
  }
}

.dashboard-table--wrap {
  max-height: 700px !important;
  overflow: auto;

  table {
    tr {

      th,
      td {
        padding: 10px;
        border-radius: 0 !important;

        &:nth-child(13) {
          min-width: 180px;
        }

        &:nth-child(7),
        &:nth-child(17) {
          min-width: 150px;
        }

        &:last-child {
          text-align: center;
        }

        &:first-child {
          min-width: 100%;
        }
      }
    }
  }

  .tablecollapsebutton {
    background: none;
  }
}

.ant-popover.dashboardPopoverNotes {
  z-index: 1 !important;

}

.notesButtonDelete {
  background: none;
  color: red;
  cursor: pointer;
  position: relative;
  /* right: -153px; */
  float: right;

  // top: -132px;
  svg {
    font-size: 20px;
  }
}

.selectTypeSearchDashBoard {
  label {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    //padding-left: 25px;
    cursor: pointer;
  }

  .form-check-input {
    opacity: 1 !important;
  }

  .form-check-input:checked {
    background-color: red;
    border-color: red;
  }

  .form-check {
    margin-bottom: 20px;
    padding-left: 0;
    margin-right: 20px;

    input {
      margin-left: 0;
    }
  }
}

.dahboardExapndRow {
  text-align: left;

  .dahboardExapndLabel {
    font-weight: bold;
  }
}

.dashboardPopoverNotes .ant-popover-inner-content {
  max-height: 300px;
  overflow-y: auto;
}

.notesUserName {
  font-weight: 500;
  text-align: end;
}

hr.hrNotesLine {
  border-top: 2px solid black;
}

.notesData {
  word-wrap: break-word;
}


// .insertCarrierContent {
//   .row {
//     .col-12 {
//       &:last-child {
//         .card {
//           margin: 0;
//           height: 100%;
//         }
//       }
//     }
//   }
// }

.dbLimit {
  label {
    color: #333;
    line-height: 1.3;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
    // margin: 0 0 10px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
  }

  .dbError {
    font-size: 12px;

  }

  .ant-select {
    width: 100%;
  }

 
  width: 100%;
  @media screen and (min-width:960px) {
    max-width: 300px;
  }
  @media screen and (max-width:567px) {
    max-width: 100%;
  }
}

.app_form .headquaterSpinner .ant-spin {
  z-index: 5 !important;
}

.sortBtb {
  background-color: #f5f5f5;
}

.userSpinner {
  position: relative;
}

@media screen and (max-width: 768px) {

  .userSpinner {
    width: 100%;
    margin-bottom: 15px;
  }
}

.userSpinner .ant-spin-spinning {
  position: absolute;
  left: 0;
  right: 0;
  top: 7px;
}

.columnCursor {
  cursor: default;
}

.reloadDropdown {
  background-color: white;

}

.reloadCarrierCheckBox {
  min-width: 10px !important;
}

.sortBtb {
  background-color: #f5f5f5;
}

.userHeader.dropdown button,
.userHeaderProfile button {
  height: 100%;
  box-shadow: none !important;
  border: none !important;
}

.userHeaderProfile .dropdown-menu {
  width: 170px;
  padding: 0;

  .dropdown-item {
    white-space: inherit;
    width: 100% !important;
    height: 100% !important;
    justify-content: space-between !important;
    display: flex !important;
    line-height: 22px;
    font-size: 16px;
    padding: 15px 20px !important;

    &:hover {
      background-color: #f5f5f5 !important;
    }

  }


}

.userHeaderProfile:hover .dropdown-menu {
  display: block !important
}

.userHeader:hover .dropdown-menu {
  display: block !important
}

.notificationScroll {
  display: block !important;
}

.manageStaffAccessDropdown button::after {
  display: none;
}

.manageStaffAccessDropdown {
  .dropdown-item {
    &:hover {
      background-color: rgba(254, 0, 0, 0.05) !important;
    }

  }
}

.appTabs_container {

  .nav {
    li {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0;
      font-size: 16px;
      font-weight: 600;
      background: transparent;
      border: 0;
      outline: none;
      color: #333333;
      cursor: pointer;

      button {
        width: 100%;
        padding: 20px 25px;
        background-color: transparent !important;
        border: none;
        border-bottom: 5px solid transparent;
        color: #333333;
        font-size: 16px;

        @media screen and (max-width: 480px) {
          font-size: 14px;
          padding: 15px 20px;
        }

        &[aria-selected="true"] {
          border-bottom: 5px solid #fe0000;
          color: #fe0000;
        }
      }
    }
  }

}

.userHeader .dropdown-menu {
  padding: 20px;
  width: 365px;

  @media screen and (max-width:767px) {
    width: 270px;
  }

  .dropdown-item {
    white-space: pre-wrap;
    word-break: break-all;
    width: 100% !important;
    height: 100% !important;
    justify-content: inherit !important;
    display: flex !important;
    line-height: 22px;
    font-size: 14px;
    padding: 5px 0 10px 27px !important;
    border: none !important;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(176, 186, 201, 0.45) !important;

    .status_dot {
      background-color: #00bf26;
      border-radius: 100px;
      display: inline-block;
      height: 12px;
      width: 12px;
      position: absolute;
      left: 0;
      top: 10px;
    }

    &:hover {
      background-color: #e6f7ff !important;
    }

    .grey-text {
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.3;
      margin: 5px 0 0;
      position: relative;
      width: 100%;
    }


  }

  .dropFooter {
    padding: 20px 0 0;
    width: 100%;
    position: relative;
  }

  .viewmore {
    box-shadow: none;
    border: none;
    color: #fe0000 !important;
    background-color: rgba(254, 0, 0, 0.1) !important;
    border: none !important;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }

  .notificationScroll.ps {
    max-height: 238px;
    margin: 0 -15px;
    padding: 0 15px;
    border: none;
  }
}


.rfpSectionList {
  padding-left: 24px;

  .rfpSectionButton {
    background: none;
  }

}

.uploadTableCoulmnSection {
  justify-content: flex-end;
}

.advanceBtn {
  cursor: default;

  span {
    cursor: pointer;
  }

  .advanceSvg {
    cursor: pointer;
  }
}

.authBtn {
  font-size: 16px;
}

.boxOuter {
  height: auto;

  button {
    background: white;
  }
}

.analyzerTbl {
  max-height: 500px;
}

.insertCarrierDataFileChoose {
  padding: 10px 15px !important;
}

.RFPUploadFileChoose {
  padding: 10px 15px !important;
}

.insertCarrierDataFileChoose {
  padding: 10px 15px !important;
}

.download {
  box-shadow: 0 2px 9px rgb(165 165 165 / 15%);
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #cccccc;
  display: flex;
  height: 44px;
  justify-content: center;
  line-height: 1.3;
  padding: 5px 10px;
  position: relative;
  transition: all 0.25s ease;
  width: 44px;
  color: #000000;
}

.wkHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (max-width: 820px) {
    gap: 10px;
  }
}

.wkHead .input-field {
  margin: 0;
}

.form-group input[type="number"]::-webkit-outer-spin-button,
.form-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group input[type="number"] {
  -moz-appearance: textfield;
}

.dollar {
  position: relative;
  width: 100%;
}

.dollar .input-group-text {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  padding: 15px !important;
}

.dollar input {
  padding-left: 35px !important;
}

.paddingStart {
  margin-left: 20px !important;
  list-style-type: none;
}

.reloadCarrierTable .table-responsive {
  overflow: auto !important;
}

.syncDataSetting {
  width: 126px;
  letter-spacing: 0 !important;
}

.table-section .column-field {
  margin: 0;
}

.headerProfileDropdown {
  padding-top: 0 !important;
  padding-left: 30px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border: 1px solid #0000002d;
    border-radius: 5px;
    box-shadow: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;

      button {
        white-space: inherit;
        width: 100% !important;
        height: 100% !important;
        justify-content: space-between !important;
        display: flex !important;
        line-height: 22px;
        background: rgba(255, 255, 255, 0.15);
        text-shadow: none !important;
        color: #333333;
        font-size: 16px;
        padding: 15px 20px !important;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.headerNotificationDropdown {
  padding-right: 30px;

  @media screen and (max-width: 991px) {
    padding-right: 0px;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border: 1px solid #0000002d;
    border-radius: 5px;
    box-shadow: none;
  }

  .ant-popover-inner-content {
    padding: 20px;
    max-width: 396px;
    width: 100%;
  }

  .notificationScroll.ps {
    max-height: 238px;
    margin: 0 -15px;
    padding: 0 15px;
    border: none;

    .dropdown-item {
      white-space: pre-wrap;
      word-break: break-all;
      width: 100% !important;
      height: 100% !important;
      justify-content: inherit !important;
      display: flex !important;
      line-height: 22px;
      font-size: 14px;
      padding: 5px 0 10px 27px !important;
      border: none !important;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(176, 186, 201, 0.45) !important;
      position: relative;

      .status_dot {
        background-color: #00bf26;
        border-radius: 100px;
        display: inline-block;
        height: 12px;
        width: 12px;
        position: absolute;
        left: 0;
        top: 10px;
      }

      &:hover {
        background-color: #e6f7ff !important;
      }

      .grey-text {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.3;
        margin: 5px 0 0;
        position: relative;
        width: 100%;
      }


    }


  }


  .dropFooter {
    padding: 20px 0 0;
    width: 100%;
    position: relative;
  }

  .viewmore {
    box-shadow: none;
    border: none;
    color: #fe0000 !important;
    background-color: rgba(254, 0, 0, 0.1) !important;
    border: none !important;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
}

.black-text1.ant-popover-open {
  color: #333 !important;
}


.manageStaffAccessActionDropdown {
  padding: 0;



  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    max-width: 185px;
    padding: 0;
  }

  .ant-popover-inner {
    position: relative;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  button {
    display: inline-flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 1.3;
    padding: 10px 15px;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;

    &:hover {
      background-color: #f5f5f5 !important;
    }
  }

}

.insertCarrierDataSearchInputField {
  .ant-select-clear {
    right: 35px;
  }
}

.swapOriginDestinationDashboardButton {
  @media screen and (max-width:567px) {
    transform: rotate(90deg);
  }

}

.metricesbutton1 {
  @media screen and (max-width:767px) {
    margin-top: 0 !important;
  }
}

@media screen and (max-width:767px) {
  .dotmcnumbersearchbutton {
    width: auto !important;
  }

  .sechdr_2 button {
    margin-bottom: 10px;
  }

  .f18 {
    font-size: 18px
  }
}

.ant-picker-range-separator {
  padding: 0 3px;
}

.db-limit-row {
  @media screen and (max-width:567px) {
    .btn-group {
      justify-content: flex-start !important;
      margin-top: 10px;
    }
  }

}

.profileImageDiv {
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
  background: #eeeeee61;
}

.analyzerControls {
  max-height: calc(100vh - 120px);
  overflow: auto;
  position: sticky;
  top: 159px;

  .card.boxOuter {
    h4 {
      font-size: 16px;
      line-height: 25px;
      padding-right: 10px;
    }

    .app_form {
      h5 {
        line-height: 18px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 30px;

  }
}

.analyzerControls .card:last-child {
  margin-bottom: 0;
}

#root {
  overflow: inherit !important;
}

.calculationLoader {
  width: 25px;
}

.sticky {
  position: sticky;
  top: 70px;
  z-index: 9;
  background: #ebf1f4;
}

.applyRfp {
  border: 2px solid #e8e8e8;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  min-height: initial;
  padding: 0 15px;
  text-transform: initial;

}
.btn-edit-role{
  border: 2px solid #6969e4 !important;
  color: #6969e4 !important;
  margin-right: 20px;
}

.btn-edit-role:hover, .btn-edit-role:active{
    color: #ffffff !important;
    background-color: #6969e4 !important;
}

.btn-edit-role-disabled{
  border: 2px solid #4d4f5c !important;
  color: #4d4f5c !important;
  margin-right: -15px  !important;
}
.btn-edit-role-disabled:hover, .btn-edit-role-disabled:active{
  color: #ffffff !important;
  background-color: #4d4f5c !important;
}
.applyOTP{
  font-size: 2rem !important;
  box-sizing: border-box !important;
  width: 100% !important;
  margin: 0 5px 46px !important;
  padding: 0px !important;
  transition: box-shadow .3s, border .3s !important;
  border-radius: 0 !important;
  outline: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid black !important;
}

.ant-tooltip, .ant-tooltip-content{
  max-width: max-content !important; 
}
.ant-tooltip-inner{
  max-width: 550px;
}
.rfp-setting{
    border-right: 1px solid #b0bac9;
    padding-right: 30px;
    @media screen and (max-width:960px) {
      padding-top: 30px;
      } 
}
.switch_ele_rfp, .switch_ele_setting_rfp{
  width:81px
}
.switch_ele_rfp label input[type=checkbox]:checked + .lever::after {
  left: 52px;
}
.ant-tooltip-content{
  max-width: 351px !important;
}
.applyOTP{
    font-size: 2rem !important;
    box-sizing: border-box !important;
    width: 100% !important;
    margin: 0 5px 46px !important;
    padding: 0px !important;
    transition: box-shadow .3s, border .3s !important;
    border-radius: 0 !important;
    outline: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid black !important;
}
.mfr_resend:active,.mfr_resend:hover,.mfr_resend:focus{
  outline-style: dotted !important;
  outline-color: #dc3545 !important;
  outline-width: 2px !important;
  padding: 2px;
}
img.reload_loading_img {
  display: inline-block;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 50px;
  background: rgba(255, 255, 255, 0.85);
  z-index: 9;
}
.form-group label{
  margin-bottom:5px;
}

.emailfilterapplybutton{
  width: 100%;
  button{
    width: 100%;
  }
}

.card .card-header{
  .sec_title{
    margin: 0;
  }
}
.btn-default.btn-open{
    background-color: rgba(0, 0, 0, 0);
    border-color: #fe0000;
    color: red;
}

.carrier-search-form{
  .form-btns{
    gap:20px
  }
}
.metricsdatepicker{
  .ant-picker-input{margin-right: 15px;}
}

// .custom-datepicker-box .ant-picker {
//   gap: 8.5%;  
//   @media screen and (max-width: 560px) {
//     gap: 0;
//   }
// }

// .custom-datepicker-box span.ant-picker-separator {
//   color: rgb(0 0 0);
//   font-size: 22px;
// }

.card .card-header{
  gap: 15px;
}

.btn-default{
  white-space: nowrap;
  line-height: normal;
}

.row-gap{
  row-gap: 15px;
}

.card .card-header .fltr_cell input.search_formControl{
  margin: 0;
  width: 100% !important;
}

.metricSelect .filtersec > div{
  margin: 0;
}

.handlecollapsebuttoninsertcarrierdata button img,
.handleDrayageSectionCollapse button img {
  width: 30px;
}

// tr th,tr td{
//   white-space: nowrap;
// }

table tr td, table tr th{
  padding: 15px 20px;
}

.form-group label {
  color: #333;
  line-height: 1.3;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .app_form .btn--wrap{
    gap: 10px;
  }
  // .filterapllybutton{
  //   margin: 0;
  // }
}

@media screen and (max-width: 820px) {
  .card .card-header input.search_formControl{
    flex: auto !important;
    width: auto !important;
  }
  .app_form .btn--wrap{
    gap: 0;
  }
  .card .card-header{
    justify-content: flex-start;
    // margin: 0;
  }
  .delete-email.ant-tooltip-open{
    display: none;
  }
}
.otherDbSettings{
  display:flex;
  border-right:1px solid #b0bac9;
  @media screen and  (max-width: 760px){
    display: contents;
  }
  @media screen and (min-width:860) {
   padding-right: 30px;
    }
    
    .button-btn-default {
      width: 214px;
    }
}

.otherEmailSellings{
  display:flex;
  @media screen and  (max-width: 760px){
    display: contents;
  }
  @media screen and (min-width:567px) {
  padding-top: 30px;
  }
  @media screen and (min-width:860) {
    padding-left: 30px;
    }
}

.justify-content-end-limit{
  justify-content: flex-end !important;
  @media screen and (max-width:760px) {
    justify-content: flex-start !important;
    padding-top: 10px;
    padding-bottom: 10px;
    }
}

.permissionItem {
  margin-left: 32px;
  margin-right: -35px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.permissionSwitch {
  margin-left: 25px;
}
.permissionSubPage{
  margin-left: 55px;
  margin-right: -74px;
}
.permissionUnsub{
  margin-left: 20px;
}

.dateRangeBlackBorder {
  height: 46px;
  padding: 5px 15px !important;
  border-radius:5px;
  border: 1px solid #080808 !important;
}
.dateRangeWithoutBorder{
  height: 46px;
  padding: 5px 15px !important;
  border-radius:5px;
  border: 1px solid #cccccc !important;
}

.app_form .form-group .dateRange input {
  border: none !important;
}

.dateRangeReload {
  border: none !important;
}

.dateRangeReload input:first-child {
  border: 1px solid #000 !important;
}

.dateRangeReload input:last-child {
  border: 1px solid #000 !important;
}
.ant-radio-inner{
  border:none !important;
}
.ant-radio-inner :hover{
  border:none !important;
}
.ant-radio-input:focus + .ant-radio-inner {
   box-shadow: none !important;
}
[type=radio]:checked+span, [type=radio]:not(:checked)+span{
  height:20px !important;
  padding-left: 25px !important;
}
span.ant-radio + * {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.iconPermissionItem{
  padding-top: 4px;
    padding-bottom: 4px;
}
.PCStartDate {
  .ant-picker-clear {
    right: 25px;
  }
}
.PCEndDate {
  .ant-picker-clear {
    right: 25px;
  }
}