
.customSelect.bootstrap-select {
	&:not([class*="col-"]) {
		&:not([class*="form-control"]) {
			&:not(.input-group-btn) {
				width: 100%;
			}
		}
	}
	.btn.dropdown-toggle {
		background: #ffffff;
		border: 1px solid #eee;
		border-radius: 5px;
		font-size: 20px;
		height: 40px;
		line-height: 1.3;
		padding: 5px 20px;
		.filter-option {
			display: inline-flex;
			align-items: center;
			// justify-content: center;
		}
		&::after {
			background-color: rgba($color: #000000, $alpha: 0);
			// background-image: url('../../images/icon-select-arrow.png');
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: 100% auto;
			border: none;
			height: 36px;
			margin: 0;
			width: 36px;
		}
	}
	.btn.dropdown-toggle.btn-light {
		&:not(:disabled) {
			&:not(.disabled).active {
				color: #333333;
				background-color: #f5f5f5;
				border-color: #eee;
				&:focus {
					box-shadow: none !important;
					outline: none !important;
				}
			}
			&:not(.disabled) {
				&:active {
					color: #333333;
					background-color: #f5f5f5;
					border-color: #eee;
					&:focus {
						box-shadow: none !important;
						outline: none !important;
					}
				}
			}
		}
	}
	.dropdown-toggle {
		&:focus {
			outline: none !important;
			outline: none !important;
			box-shadow: 0 0 2px rgba(38, 190, 85, 0.5);
		}
	}
	& > select.mobile-device {
		&:focus+.dropdown-toggle {
			outline: none !important;
			outline: none !important;
			box-shadow: 0 0 2px rgba(38, 190, 85, 0.5);
		}
	}
	.dropdown-menu {
		&:not(.inner) {
			border-bottom: 1px solid #E7E7E7;
			border-radius: 10px;
			min-width: 150px;
			text-align: right;
			// left: auto !important;
			// right: 0;
		}
		.dropdown-item {
			border-bottom: 1px solid #E7E7E7;
			color: #9A9A9A;
			font-size: 14px;
			line-height: 1.3;
			padding: 7px 25px;
			position: relative;
			span.text {
				font-size: inherit;
				line-height: inherit;
				color: inherit;
			}
			&:hover {
				color: #333333;
			}
			&:active {
				color: $primary-color;
				background-color: rgba($primary-color, 0.15);
			}
		}
		li {
			&:last-child {
				.dropdown-item {
					border-bottom: none;
				}
			}
		}
		.dropdown-item.active {
			color: $primary-color;
			background-color: rgba($primary-color, 0.15);
		}
	}
}

.customSelect.bootstrap-select.show {
	>.btn.dropdown-toggle.btn-light.dropdown-toggle {
		color: #333333;
		background-color: #f5f5f5;
		border-color: #eee;
		&:focus {
			box-shadow: none !important;
			outline: none !important;
		}
	}
}
