@import '../abstracts/variables';
@import '../abstracts/mixins';

/* ====================================================================
                    Toggle Button Animation Styleing
===================================================================== */
@keyframes burgerAnimationSlide {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    49% {
        transform: translateX(-0.7em);
        opacity: 0;
    }

    50% {
        transform: translateX(0.7em);
        opacity: 0;
    }

    51% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.burger {
    cursor: pointer;
    font-size: 12px !important;
    height: 30px;
    padding: 0 !important;
    position: relative;
    transition: .2s all;
    width: 30px;
}

.burger:after {
    content: '';
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
    pointer-events: none;
    display: block;
    content: '';
    width: 100%;
    border-radius: 25em;
    background-color: $primary-color;
    height: .25em;
    height: 3px;
    position: absolute;
    transform: rotate(0);
}

.burger .burger-lines {
    top: 50%;
    margin-top: -0.125em;
    margin-top: -1.5px;
}

.burger .burger-lines .burger-lines:after {
    left: 0;
    top: -0.8em;
}

.burger .burger-lines .burger-lines:before {
    right: 0;
    top: .8em;
}

.burger.burger-slide .burger-lines {
    transition: .1s all .15s;
}

.burger.burger-slide .burger-lines:after,
.burger.burger-slide .burger-lines:before {
    width: 20px;
    transition: .1s all .15s;
}

.burger.burger-slide .burger-lines:after {
    left: 0;
    top: -0.5rem;
}

.burger.burger-slide .burger-lines:before {
    right: 0;
    top: .5rem;
}

.burger.burger-slide:not([aria-expanded="true"]) {
    animation-name: burgerAnimationSlide;
    animation-duration: .4s;
    background-color: transparent;
}

.burger.burger-slide[aria-expanded="true"] .burger-lines {
    animation-name: burgerAnimationSlide;
    animation-duration: .4s;
    background-color: transparent;
}

.burger.burger-slide[aria-expanded="true"] .burger-lines:after,
.burger.burger-slide[aria-expanded="true"] .burger-lines:before {
    left: 5px;
    top: 0;
    right: auto;
}

.burger.burger-slide[aria-expanded="true"] .burger-lines:before {
    transform: rotate(-45deg);
}

.burger.burger-slide[aria-expanded="true"] .burger-lines:after {
    transform: rotate(45deg);
}

/* ====================================================================
                  Toggle Button Animation Style Ends
===================================================================== */


// ========================================================================
//                        Component: top-navbar
// ========================================================================

.main-header {
    position: relative;
    padding: 0;
    z-index: 10;

    .navbarWrap {
        padding: 60px 75px 0;
        position: absolute;
    }

    .navbar {
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        box-shadow: 0 15px 30px rgba(133, 133, 133, 0.15);
        padding: 20px;

        .navbar-brand {
            padding: 0 10px;
        }

        .navbar-nav {
            .nav-item {
                position: relative;

                .nav-link {
                    color: $secondary-color;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 0 20px;

                    &:hover {
                        color: $link-hover-color;
                    }
                }

                &::before {
                    background-color: rgba(0, 0, 0, 0.0);
                    // background-image: url('../../images/nav_active-img.png');
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    bottom: 100%;
                    content: '';
                    display: inline-block;
                    height: 7px;
                    left: 50%;
                    margin-bottom: 25px;
                    margin-left: -24px;
                    position: absolute;
                    @include transform(scale(0));
                    @include transition (all 0.25s ease);
                    width: 48px;
                }

                &.active {
                    .nav-link {
                        color: $primary-color;
                        text-shadow: 0 2px 6px rgba($primary-color, 0.45);

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    &::before {
                        @include transform(scale(1));
                    }
                }

            }

            .dropdown-menu {
                border-color: #ececec;
                @include box-shadow(0 10px 20px rgba(0, 0, 0, 0.05));
                border-radius: 15px;
                left: auto;
                margin-top: 30px;
                right: 0;

                .dropdown-item {
                    color: $text-color;
                    padding: 10px 40px;

                    &.active,
                    &:active {
                        color: #fff;
                        background-color: $primary-color;
                    }
                }
            }
        }

        &.fixed-top {
            background-color: #ffffff;
            border-width: 0 0 1px;
            border-color: #f1f1f1;
            border-radius: 0;
            @include box-shadow(0 15px 30px rgba(133, 133, 133, 0.055));
        }
    }
}

// Style changes by client later
.main-header {
    .navbarWrap {
        padding: 0;
    }

    .navbar {
        @include box-shadow(0 15px 30px rgba(107, 107, 107, 0.1));
        border-color: #ebebeb;
        border-radius: 0;
        border-style: solid;
        border-width: 0 0 1px;
    }
}

// ========================================================================
//                    Responsive Style Starts from here
// ========================================================================

@media screen and (max-width: 1440px) {
    .main-header {
        .navbar {
            padding: 15px 15px;

            .navbar-nav {
                .nav-item {
                    &::before {
                        margin-bottom: 18px;
                    }

                    .nav-link {
                        font-size: 16px;
                    }
                }

                .dropdown-menu {
                    margin-top: 25px;
                }
            }

            .navbar-brand {
                width: 230px;
            }
        }
    }
}

@media screen and (max-width: 1366px) {}

@media screen and (max-width: 1199px) {
    .main-header {
        .navbar {
            .navbar-brand {
                width: 185px;
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .main-header {
        .navbarWrap {
            padding: 35px 70px 0;
        }

        .navbar {
            background-color: rgba(255, 255, 255, 1);
            padding: 15px 20px;

            .navbar-nav {
                margin-top: 20px;

                .nav-item {
                    .nav-link {
                        padding: 10px 20px;
                    }

                    &::before {
                        content: none;
                    }
                }

                .dropdown-menu {
                    margin-top: 0;
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 540px) {
    .main-header {
        .navbarWrap {
            padding: 0;
        }

        .navbar {
            background-color: #ffffff;
            border-width: 0 0 1px;
            border-color: #f1f1f1;
            border-radius: 0;
            @include box-shadow(0 15px 30px rgba(133, 133, 133, 0.055));
        }
    }

}

@media screen and (max-width: 480px) {}

@media screen and (max-width: 380px) {}