//Bootstrap 4 grid tiers and media query breakpoints
// xs = Extra small <576px
// Max container width None (auto)

// sm = Small ≥576px
// Max container width 540px

// md = Medium ≥768px
// Max container width 720px

// lg = Large ≥992px
// Max container width 960px

// xl = Extra large ≥1200px
// Max container width 1140px

@mixin clearfix(){
  &:before,
  &:after{
    content: " "; 
    display: table; 
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}


// =============== rem font size with pixel fallback ===============
@function calculateRem($size) {
  $remSize: calc($size / 16px);
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
// p {
//   @include font-size(14px)
// }





// =============== border-radius ===============
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
// @include border-radius(14px)





// =============== Single side border-radius ===============
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}
// @include border-left-radius(14px)





// =============== Box Sizing ===============
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}
// @include box-sizing(border-box)





// =============== Box shadow ===============
@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow ;
          box-shadow: $box-shadow;
}





// =============== Placeholder ===============

@mixin placeholder {
  &::-webkit-input-placeholder {
      @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
      @content;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
      @content;
  }

  &:-ms-input-placeholder {
      @content;
  }
}




// =============== transition ===============
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}



// =============== Breakpoints ===============
@mixin breakpoint($point) {
  @if $point == xxxlarge {
    @media (max-width: 1600px) { @content; } /* 1600 px*/
  }
  @if $point == xxlarge {
    @media (max-width: 1280px) { @content; } /* 1280 px*/
  }
  @if $point == xlarge {
    @media (max-width: 1199px) { @content; } /* 1199 px*/
  }
  @if $point == large {
    @media (max-width: 1024px) { @content; } /* 1024 px*/
  }
  @else if $point == medium {
    @media (max-width: 991px) { @content; } /* 991 px*/
  }
  @else if $point == small {
    @media (max-width: 767px)  { @content; } /* 767 px*/
  }
  @else if $point == x-small {
    @media (max-width: 480px)  { @content; } /* 480 px*/
  }
}





// =============== Retina ===============
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {                             
            background-image: url($image);          // on retina, use image that's scaled by 2
            background-size: $width $height;        // @include image-2x("url", 100px, 25px);
         
          }
}





// =============== Transition ===============
@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
      -ms-transition: $args;
       -o-transition: $args;
          transition: $args;
}
@mixin transition-property($property...) {
  -webkit-transition-property: $property;
     -moz-transition-property: $property;
       -o-transition-property: $property;
          transition-property: $property;
}
@mixin transition-duration($duration...) {
  -webkit-transition-property: $duration;
     -moz-transition-property: $duration;
       -o-transition-property: $duration;
          transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -webkit-transition-timing-function: $timing;
     -moz-transition-timing-function: $timing;
       -o-transition-timing-function: $timing;
          transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
     -moz-transition-delay: $delay;
       -o-transition-delay: $delay;
          transition-delay: $delay;
}





// =============== Transformation ===============
@mixin transform($transforms) {
  -webkit-transform: $transforms;
     -moz-transform: $transforms;
      -ms-transform: $transforms;
       -o-transform: $transforms;
          transform: $transforms;
}





// =============== Animations and keyframes ===============
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
      @content;
  }
  @-moz-keyframes #{$animation-name} {
      @content;
  }  
  @-ms-keyframes #{$animation-name} {
      @content;
  }
  @-o-keyframes #{$animation-name} {
      @content;
  }  
  @keyframes #{$animation-name} {
      @content;
  }
}

// @mixin animation($str) {
// -webkit-animation: #{$str};
// -moz-animation: #{$str};
// -ms-animation: #{$str};
// -o-animation: #{$str};
// animation: #{$str};      
// }





// =============== Arrow with Four Optional Directions ===============
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent; 
     border-right: $size solid $color;
  }
  @else if ($direction == right) {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent; 
     border-left: $size solid $color;
  }
  @else if ($direction == down) {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-top: $size solid $color;
  }
  @else {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-bottom: $size solid $color;
  }
}
// with custom arguments
// div {
//   @include arrow(up, 10px, #efefef);
// }